const requiredPhoto = (field_name, size) => [
    v => !!v || `${field_name} is required!`, value => !value || value.size < size || '檔案大小不得超過 3MB! Photo size should be less than 3 MB!',
    (value) => {
        console.log(value);
        const validTypes = ['image/heic', 'image/jpg', 'image/jpeg', 'image/png'];
        return !value || validTypes.includes(value.type) || '檔案格式不支援! Not supported file type!';
    }
]

const optionalPhoto = (size, image_urls, images) => [
    value => !value || value.size < size || '檔案大小不得超過 3MB! Photo size should be less than 3 MB!',
    (value) => {
        const validTypes = ['image/heic', 'image/jpg', 'image/jpeg', 'image/png'];
        return !value || validTypes.includes(value.type) || '檔案格式不支援! Not supported file type!';
    },
    () => {
        let isValid = false;
        for (let i = 0; i < image_urls.length; i++) {
            if (images[i]) {
                isValid = true;
            }
        }
        return isValid;
    }
]

const updateOptionalPhoto = (size) => [
    value => !value || value.size < size || '檔案大小不得超過 3MB! Photo size should be less than 3 MB!',
    (value) => {
        const validTypes = ['image/heic', 'image/jpg', 'image/jpeg', 'image/png'];
        return !value || validTypes.includes(value.type) || '檔案格式不支援! Not supported file type!';
    }
]

module.exports = {
    requiredPhoto,
    optionalPhoto,
    updateOptionalPhoto
}