const axios = require('axios')
const presignedUrlPath = process.env.VUE_APP_API_DOMAIN + '/presigned_url'
const createSubPath = (engineer_no) => `${process.env.VUE_APP_API_DOMAIN}/engineer/${engineer_no}/submission`
const getSubByLocationIdPath = (engineer_no, location_id) => `${process.env.VUE_APP_API_DOMAIN}/engineer/${engineer_no}/submission?location_id=${location_id}`
const getSubById = (engineer_no, sub_id) => `${process.env.VUE_APP_API_DOMAIN}/engineer/${engineer_no}/submission/${sub_id}`
import Cookies from 'js-cookie'

export default class SubmissionService {
    reqHeader = {
        withCredentials: true
    };

    getPresignedUrls(images) {
        //add the Authorization header
        this.reqHeader.headers = {
            "Authorization": Cookies.get('token_payload')
        };

        //get the file names
        // console.log(images);
        let fileNames = [];
        //post request with the file names as array in request body
        for (let i = 0; i < images.length; i++) {
            if (images[i]) {
                console.log(images[i].name);
                fileNames[i] = images[i].name;
            } else {
                fileNames[i] = '';
            }
        }

        // console.log(fileNames);

        //return the presigned Urls
        return new Promise((resolve, reject) => {
            axios.post(presignedUrlPath, fileNames, this.reqHeader).then((res) => {
                if (res.data.success) {
                    resolve(res.data.result);
                } else {
                    reject('系統錯誤，請重試 Generate URL failed, please try again!');
                }
            }).catch((err) => {
                reject(err)
            })
        });
    }

    uploadToS3(image, presignedUrl) {
        var options = {
            headers: {
                'Content-Type': image.type
            }
        };

        return new Promise((resolve, reject) => {
            axios.put(presignedUrl, image, options).then((res) => {
                resolve(res);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    createSubmission(engineer_no, sub) {
        //add the Authorization header
        this.reqHeader.headers = {
            "Authorization": Cookies.get('token_payload')
        };

        return new Promise((resolve, reject) => {
            axios.post(createSubPath(engineer_no), sub, this.reqHeader).then((res) => {
                resolve(res);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    getSubmissionsByLocationId(engineer_no, location_id) {
        //add the Authorization header
        this.reqHeader.headers = {
            "Authorization": Cookies.get('token_payload')
        };

        return new Promise((resolve, reject) => {
            axios.get(getSubByLocationIdPath(engineer_no, encodeURIComponent(location_id)), this.reqHeader).then((res) => {
                resolve(res);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    updateSubmission(engineer_no, id, data) {
        //add the Authorization header
        this.reqHeader.headers = {
            "Authorization": Cookies.get('token_payload')
        };

        return new Promise((resolve, reject) => {
            axios.patch(getSubById(engineer_no, id), data, this.reqHeader).then((res) => {
                resolve(res);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    getSubmission(engineer_no, id) {
        //add the Authorization header
        this.reqHeader.headers = {
            "Authorization": Cookies.get('token_payload')
        };

        return new Promise((resolve, reject) => {
            axios.get(getSubById(engineer_no, id), this.reqHeader).then((res) => {
                resolve(res);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });

    }
}