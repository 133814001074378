const LOCATION_LIFT_TYPE = 0;
const LOCATION_ESCAL_TYPE = 1;
const MACHINE_TYPE_ETL = 0;
const MACHINE_TYPE_HL = 1;
const MACHINE_TYPE_ESCAL = 2;
const UPLOAD_FILE_SIZE = 3145728;

const submissionTitles = {
    create: '新增記錄 New Submission',
    view: '查看記錄 View Submission',
    update: '更新記錄 Edit Submission'
}

const liftType = [{
    text: '電動曳引式升降機 Electric Traction Lifts',
    value: MACHINE_TYPE_ETL,
    components: [{
            label: '機房/機器間 Machine room or machinery space',
            prefix: 'mrms',
            optional: true,
        },
        {
            label: '限速器 Overspeed governor',
            prefix: 'og',
            optional: true
        },
        {
            label: '制動器 Operation brake',
            prefix: 'ob',
            optional: true
        },
        {
            label: '主樓層纜索與曳引輪 Rope and traction sheave at the main floor',
            prefix: 'rts-main',
            optional: true
        },
        {
            label: '頂層纜索與曳引輪 Rope and traction sheave at the top landing floor',
            prefix: 'rts-top',
            optional: true
        },
        {
            label: '井底及緩衝器 Pit and buffer',
            prefix: 'pb',
            optional: true
        }
    ]
}, {
    text: '液壓升降機 Hydraulic Lifts',
    value: MACHINE_TYPE_HL,
    components: [{
            label: '機房/機器間 Machine room or machinery space',
            prefix: 'mrms',
            optional: true
        },
        {
            label: '油壓活塞桿 Piston rod of cylinder',
            prefix: 'prc',
            optional: true
        },
        {
            label: '油壓管路 Hydraulic pipeline',
            prefix: 'hp',
            optional: true
        },
        {
            label: '井底及緩衝器 Pit and buffer conditions',
            prefix: 'pbc',
            optional: true
        },
        {
            label: '纜索(只適用於間接式油壓升降機) Suspension rope (for indirect acting hydraulic lifts only)',
            prefix: 'sr',
            optional: true
        },
    ]
}];
const escalatorType = [{
    text: '自動梯(包括乘客輸送機)  Escalators (including passenger conveyors)',
    value: MACHINE_TYPE_ESCAL,
    components: [{
            label: '扶手帶張緊裝置 Handrail tensioning device',
            prefix: 'htd',
            optional: true
        },
        {
            label: '機器間、驅動站及轉向站 Condition of driving station',
            prefix: 'cds',
            optional: true
        },
        {
            label: '驅動鏈斷裂安全裝置 Broken drive chain device',
            prefix: 'bdcd',
            optional: true
        },
        {
            label: '驅動鏈條 Drive chain',
            prefix: 'dc',
            optional: true
        },
        {
            label: '梯級鏈張緊裝置 Step chain tensioning device',
            prefix: 'sctd',
            optional: true
        }
    ]
}];
// const liftType = [{
//         machine_type: '電動曳引式升降機 Electric Traction Lifts',
//         components: [{
//                 label: '機房/機器間 Machine room or machinery space'
//             },
//             {
//                 label: '限速器 Overspeed governor'
//             },
//             {
//                 label: '制動器 Operation brake'
//             },
//             {
//                 label: 'Rope and traction sheave at the main floor'
//             },
//             {
//                 label: '主纜索及纜轆 Rope and traction sheave at the top landing'
//             },
//             {
//                 label: '井底及緩衝器 Pit and buffer'
//             }
//         ]
//     },
//     {
//         machine_type: '液壓升降機 Hydraulic Lifts',
//         components: [{
//                 label: '機房/機器間 Machine room or machinery space'
//             },
//             {
//                 label: '油壓活塞桿 Piston rod of cylinder'
//             },
//             {
//                 label: '油壓管路 Hydraulic pipeline'
//             },
//             {
//                 label: '井底及緩衝器 Pit and buffer conditions'
//             },
//             {
//                 label: '纜索(只適用於間接式油壓升降機) Suspension rope (for indirect acting hydraulic lifts only)',
//                 optional: true
//             },
//         ]
//     }
// ]

// const escalatorType = [{
//     machine_type: '自動梯(包括乘客輸送機)  Escalators (including passenger conveyors)',
//     components: [{
//             label: '扶手帶張緊裝置 Handrail tensioning device'
//         },
//         {
//             label: '機器間、驅動站及轉向站 Condition of driving station'
//         },
//         {
//             label: '驅動鏈斷裂安全裝置 Broken drive chain device'
//         },
//         {
//             label: 'Drive chain'
//         },
//         {
//             label: '梯級鏈張緊裝置 Step chain tensioning device'
//         }
//     ]
// }]

const buttonLabels = {
    create: '新增記錄 Create Submission',
    update: '更新記錄 Update Submission'
}

const submitMessage = {
    success: {
        new: '新增記錄成功 New submission created successfully',
        edit: '更新記錄成功 Submission updated succesfully'
    },
    failed: {
        new: 'Unable to create new submission',
        edit: 'Unable to update submission'
    }
}

module.exports = {
    submissionTitles,
    liftType,
    escalatorType,
    buttonLabels,
    submitMessage,
    LOCATION_LIFT_TYPE,
    LOCATION_ESCAL_TYPE,
    MACHINE_TYPE_ETL,
    MACHINE_TYPE_HL,
    MACHINE_TYPE_ESCAL,
    UPLOAD_FILE_SIZE
}